import BaseSelect from '@/components/common/base-select/base-select.vue'
import supplierSelect from '@/components/packages/supplier-select/supplier-select'
import { formatDate } from '@/utils/auth/common'
const applyStateOptions = [
  { name: '拒绝', id: '0' },
  { name: '通过', id: '1' }
  // { name: '申请中', id: '2' },
]
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '供应商名称',
      prop: 'supplierName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        filterable: true,
        selectedField: ['enterpriseName', 'enterpriseName'],
        maxlength: 50,
        ref: 'searchSelect'
      },
      tag: supplierSelect,
      isHidden: content.isSupplier
    },
    // {
    //   label: '预授信申请编号',
    //   prop: 'capitalSideCode',
    //   attrs: {
    //     placeholder: '请输入'
    //   }
    // },
    {
      label: '预审批状态',
      prop: 'applyState',
      attrs: {
        placeholder: '请选择',
        options: applyStateOptions,
        selectedField: ['id', 'name'],
        clearable: true
      },
      tag: BaseSelect
    }
  ]
}

// 表格form
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '供应商名称',
      prop: 'supplierName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      minWidth: '150px',
      'show-overflow-tooltip': true
    },
    {
      label: '申请授信银行',
      prop: 'capitalSideName',
      minWidth: '150px'
    },
    // {
    //   label: '预授信申请编号',
    //   prop: 'keyId',
    //   minWidth: '150px'
    // },
    {
      label: '预申请时间',
      prop: 'applyTime',
      minWidth: '150px',
      formatter: row => {
        return formatDate(row.applyTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    // {
    //   label: '预申请结束时间',
    //   prop: 'applyEndTime',
    //   minWidth: '150px',
    //   formatter: row => {
    //     return formatDate(row.applyEndTime, 'YY-MM-DD')
    //   }
    // },
    {
      label: '预审批状态',
      prop: 'applyState',
      minWidth: '150px',
      formatter: row => {
        const [obj] = applyStateOptions.filter(item => item.id === row.applyState)
        return obj ? obj.name : ''
      }
    },
    {
      label: '预授信额度',
      prop: 'creditAmt',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.creditAmt ? row.creditAmt.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '拒绝原因',
      prop: 'refusalReason',
      minWidth: '150px',
      align: 'left',
      formatter: row => {
        return row.refusalReason ? row.refusalReason : '--'
      }
    },
    {
      label: '操作',
      prop: 'action',
      minWidth: '120px',
      fixed: 'right'
    }
  ]
}
