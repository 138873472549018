<template>
  <!-- 预授信申请管理列表 -->
  <div class="financingDecisionManageList">
    <!-- 条件查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
      <!-- <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div> -->
    </base-form>
    <div class="main-page-table__header">
      <base-button label="申请预授信" v-if="isShowBtn" icon="el-icon-plus" @click="applyFor('', 'A')"/>
    </div>
    <!-- 列表数据 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :dataSource.sync="tableData"
      :api="api"
      :getApi="'preCreditPage'"
      :loadCount="loadCount"
      :queryParas='queryParas'
      ref="tableData"
      :webPage="false">
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <template slot="action" slot-scope="scope">
        <IconButton  @click="applyFor(scope.row, 'V')" content="查看" icon="iconfont iconchakan"></IconButton>
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { psbcApi } from '@/api/psbcApi'
import { getDictLists } from '@/filters/fromDict'
import storage from '@/utils/storage'
export default {
  name: 'financingDecisionManageList',
  components: { baseForm, BaseTable, BaseButton, IconButton },
  data () {
    return {
      visible: false,
      count: 0,
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [{}],
      applyType: 'A'
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return psbcApi
    },
    fileMakeData () {
      return getDictLists('FILE_MAKE_STATUS')
    },
    // 判断机构类型是不是稳城一方人员显示按钮
    isShowBtn () {
      return this.$store.state.user.userInfo.userType === '20'
    },
    // 判断是不是供应商
    isSupplier () {
      return this.$store.state.user.userInfo.userType === '22'
    }
  },
  mounted () {
    this.handleFilter()
  },
  activated () {
    this.handleFilter()
  },
  methods: {
    handleFilter () {
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 申请预授信与查看
    applyFor (data, type) {
      this.applyType = type
      this.$router.push({
        path: '/business/applyForRufs',
        query: { type: type, capitalSideCode: data.capitalSideCode }
      })
      storage.setSession('preCreditInfo', data)
    }
  }
}
</script>
x
